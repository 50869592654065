import React from "react"
import PropTypes from "prop-types"

export default function AppleLogoIcon({ color, height, width, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "31"}
      height={height || "37"}
      fill="none"
      viewBox="0 0 31 37"
      className={className}
    >
      <path
        fill={color || "#fff"}
        d="M30.35 28.835a20.15 20.15 0 01-1.978 3.576c-1.04 1.492-1.893 2.524-2.55 3.097-1.017.941-2.107 1.423-3.275 1.45-.838 0-1.85-.24-3.026-.726-1.18-.484-2.266-.724-3.258-.724-1.04 0-2.156.24-3.35.724-1.195.487-2.158.74-2.894.765-1.12.048-2.236-.447-3.35-1.489-.711-.623-1.6-1.692-2.666-3.206-1.143-1.617-2.083-3.492-2.82-5.63C.396 24.362 0 22.127 0 19.962c0-2.48.533-4.618 1.6-6.41a9.415 9.415 0 013.352-3.409 8.981 8.981 0 014.532-1.285c.89 0 2.056.276 3.505.82 1.445.545 2.373.822 2.78.822.304 0 1.335-.324 3.083-.968 1.653-.598 3.047-.846 4.19-.748 3.096.25 5.423 1.478 6.97 3.689-2.77 1.687-4.14 4.05-4.112 7.08.025 2.361.877 4.326 2.551 5.886A8.38 8.38 0 0031 27.119c-.204.597-.42 1.168-.65 1.716zM23.25.74c0 1.85-.673 3.578-2.013 5.177-1.617 1.901-3.574 3-5.695 2.826a5.778 5.778 0 01-.043-.7c0-1.777.77-3.678 2.135-5.233.682-.787 1.55-1.441 2.601-1.963C21.284.334 22.277.049 23.21 0c.027.248.039.495.039.74z"
      ></path>
    </svg>
  )
}

AppleLogoIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
