import React from "react"
import PropTypes from "prop-types"

export default function IOS14Icon({ color, height, width, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "96"}
      height={height || "96"}
      fill="none"
      viewBox="0 0 96 96"
      className={className}
    >
      <rect
        width="94"
        height="94"
        x="1"
        y="1"
        stroke={color || "#000"}
        strokeWidth="2"
        rx="21"
      ></rect>
      <path
        stroke={color || "#000"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 32l13.5-9.5V73M68 73V22.5L41.5 60H77"
      ></path>
    </svg>
  )
}

IOS14Icon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
