import React from "react"
import PropTypes from "prop-types"
import styles from "./smallQRCode.module.css"

import appStoreQRCode from "../../images/AppStoreQRCode.jpg"

export default function SmallQRCode({ color, height, width, className }) {
  return (
    <div className={`${styles.container} ${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || "37"}
        height={height || "37"}
        fill="none"
        viewBox="0 0 37 37"
        className={styles.smallQRCode}
      >
        <path
          stroke={color || "#000"}
          strokeWidth="3"
          d="M22 2v13.5h13.5V2H22zM2 22v13.5h13.5V22H2z"
        ></path>
        <path fill={color || "#000"} d="M6 26H11.5V31.5H6z"></path>
        <path fill={color || "#000"} d="M6 6H11.5V11.5H6z"></path>
        <path fill={color || "#000"} d="M26 6H31.5V11.5H26z"></path>
        <path fill={color || "#000"} d="M27 33.5H30.5V37H27z"></path>
        <path fill={color || "#000"} d="M33.5 33.5H37V37H33.5z"></path>
        <path fill={color || "#000"} d="M33.5 20.5H37V24H33.5z"></path>
        <path
          stroke={color || "#000"}
          strokeWidth="3"
          d="M22 37V22h7v2h-.5m-5 0h5m0 0v5h7v-1H29v-3M2 2v13.5h13.5V2H2z"
        ></path>
      </svg>
      <div className={styles.informationBlock}>
        <p>
          Use your smartphone camera
          <br />
          to open AppStore via QR
        </p>
        <img src={appStoreQRCode} alt="QR code"></img>
      </div>
    </div>
  )
}

SmallQRCode.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
