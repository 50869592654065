import React from "react"
import PropTypes from "prop-types"

export default function IphoneBackIcon({ color, height, width, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "55"}
      height={height || "96"}
      fill="none"
      viewBox="0 0 55 96"
      className={className}
    >
      <rect
        width="53"
        height="94"
        x="1"
        y="1"
        stroke={color || "#000"}
        strokeWidth="2"
        rx="12"
      ></rect>
      <rect
        width="8"
        height="8"
        x="8"
        y="10"
        stroke={color || "#000"}
        strokeWidth="2"
        rx="4"
      ></rect>
      <rect
        width="8"
        height="8"
        x="18"
        y="16"
        stroke={color || "#000"}
        strokeWidth="2"
        rx="4"
      ></rect>
      <rect
        width="8"
        height="8"
        x="8"
        y="22"
        stroke={color || "#000"}
        strokeWidth="2"
        rx="4"
      ></rect>
    </svg>
  )
}

IphoneBackIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
