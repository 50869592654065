import React from "react"
import PropTypes from "prop-types"
import styles from "./mobileFrame.module.css"

export default function MobileFrame({ className, svgClassName, children }) {
  return (
    <div className={`${styles.container} ${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        enableBackground="new"
        viewBox="0 0 338 679"
        width="100%"
        id="phoneFrame_blshnjdbkfhjvkyuvglsdrfkgjh"
        className={`${styles.frame} ${svgClassName}`}
      >
        <g filter="url(#dfshvmhufkgh-b)">
          <path
            fill="#C4C8CC"
            d="M7.055 87.401a3 3 0 013-3h.057a1 1 0 011 1v23.158a1 1 0 01-1 1h-.057a3 3 0 01-3-3V87.401z"
          ></path>
        </g>
        <g filter="url(#dfshvmhufkgh-c)">
          <path
            fill="#C4C8CC"
            d="M7.055 131.47a2.434 2.434 0 012.434-2.434h.812c.448 0 .811.363.811.811v42.201a.811.811 0 01-.811.811h-.812a2.434 2.434 0 01-2.434-2.434V131.47z"
          ></path>
        </g>
        <g filter="url(#dfshvmhufkgh-d)">
          <path
            fill="#C4C8CC"
            d="M7.055 189.9a2.435 2.435 0 012.434-2.435h.812c.448 0 .811.363.811.812v42.2a.812.812 0 01-.811.812h-.812a2.435 2.435 0 01-2.434-2.435V189.9z"
          ></path>
        </g>
        <g filter="url(#filter3_d_asdagdfhsfgje)">
          <path
            fill="#E0E0E2"
            d="M330.86 149.32c0-.448.363-.812.811-.812a2.435 2.435 0 012.435 2.435v67.359a2.435 2.435 0 01-2.435 2.434.81.81 0 01-.811-.811V149.32z"
          ></path>
        </g>
        <path
          fill="url(#paint0_linear_jfghhnljklb)"
          fillRule="evenodd"
          d="M285.42 0H55.75C29.754 0 8.68 21.074 8.68 47.07v584.32c0 25.996 21.074 47.07 47.07 47.07h229.67c25.996 0 47.07-21.074 47.07-47.07V47.07C332.49 21.074 311.416 0 285.42 0zm-267 47.07c0-20.618 16.714-37.331 37.331-37.331h23.603a7.191 7.191 0 014.801 6.782c0 9.925 8.046 17.97 17.97 17.97h136.11c9.925 0 17.97-8.045 17.97-17.97a7.191 7.191 0 014.802-6.782h24.414c20.618 0 37.331 16.714 37.331 37.331v584.32c0 20.618-16.713 37.332-37.33 37.332H55.751c-20.619 0-37.332-16.714-37.332-37.332V47.07z"
          clipRule="evenodd"
        ></path>
        <rect
          width="34.708"
          height="2.246"
          x="152.82"
          y="18.353"
          fill="#DCE0E3"
          stroke="url(#paint1_linear_ppdnbejk)"
          rx="1.123"
        ></rect>
        <circle
          cx="205.07"
          cy="19.477"
          r="3.558"
          fill="#DCE0E3"
          stroke="url(#paint2_linear_odnbmdjyhuh)"
        ></circle>
        <g filter="url(#filter4_f_msdkkernal)" opacity="0.6">
          <path
            fill="url(#paint3_linear_odmdyhuh)"
            fillRule="evenodd"
            d="M282.98 5.681c23.755 0 43.012 19.257 43.012 43.012v581.07c0 23.755-19.257 43.012-43.012 43.012h-58.432v1.623h58.432c24.651 0 44.635-19.984 44.635-44.635V48.693c0-24.65-19.984-44.635-44.635-44.635h-22.017v1.623h22.017z"
            clipRule="evenodd"
          ></path>
        </g>
        <g filter="url(#filter5_f_isbnwkjghs)" opacity="0.9">
          <path
            fill="url(#paint4_linear_nsdkfgkjvhv)"
            fillRule="evenodd"
            d="M57.371 5.681c-23.755 0-43.012 19.257-43.012 43.012v581.07c0 23.755 19.257 43.013 43.012 43.013h209.38v1.623H57.371c-24.651 0-44.635-19.984-44.635-44.636V48.693c0-24.651 19.984-44.635 44.635-44.635h22.017v1.623H57.371z"
            clipRule="evenodd"
          ></path>
        </g>
        <mask width="305" height="660" x="18" y="9" maskUnits="userSpaceOnUse">
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M18.467 45.107c-.034.65-.05 1.304-.05 1.963v584.32c0 20.618 16.713 37.332 37.33 37.332h229.67c20.617 0 37.331-16.714 37.331-37.332V47.07v-.241c-.13-20.506-16.794-37.09-37.33-37.09h-24.416a7.192 7.192 0 00-4.8 6.782c0 9.924-8.046 17.97-17.97 17.97h-136.11c-9.926 0-17.97-8.046-17.97-17.97a7.191 7.191 0 00-4.802-6.782H55.747a37.183 37.183 0 00-24.346 9.03 37.54 37.54 0 00-6.61 7.429 37.128 37.128 0 00-6.324 18.909z"
            clipRule="evenodd"
          ></path>
        </mask>
        <defs>
          <filter
            id="filter3_d_asdagdfhsfgje"
            width="13.246"
            height="82.228"
            x="323.86"
            y="143.51"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset dx="-2"></feOffset>
            <feGaussianBlur stdDeviation="2.5"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"></feColorMatrix>
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            ></feBlend>
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            ></feBlend>
          </filter>
          <filter
            id="filter4_f_msdkkernal"
            width="105.07"
            height="672.34"
            x="223.55"
            y="3.058"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feBlend
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            ></feBlend>
            <feGaussianBlur
              result="effect1_foregroundBlur"
              stdDeviation="0.5"
            ></feGaussianBlur>
          </filter>
          <filter
            id="filter5_f_isbnwkjghs"
            width="262.02"
            height="678.34"
            x="8.735"
            y="0.058"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feBlend
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            ></feBlend>
            <feGaussianBlur
              result="effect1_foregroundBlur"
              stdDeviation="2"
            ></feGaussianBlur>
          </filter>
          <linearGradient
            id="paint0_linear_jfghhnljklb"
            x1="332.49"
            x2="-242.5"
            y1="522.64"
            y2="640.72"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#F2F2F3"></stop>
            <stop offset="0.64" stopColor="#CACED2"></stop>
          </linearGradient>
          <linearGradient
            id="paint1_linear_ppdnbejk"
            x1="170.18"
            x2="170.18"
            y1="22.317"
            y2="18.665"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#B4BABE"></stop>
            <stop offset="0.845" stopColor="#CED1D4" stopOpacity="0"></stop>
          </linearGradient>
          <linearGradient
            id="paint2_linear_odnbmdjyhuh"
            x1="205.07"
            x2="205.07"
            y1="26.578"
            y2="17.448"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#B4BABE"></stop>
            <stop offset="0.845" stopColor="#CED1D4" stopOpacity="0"></stop>
          </linearGradient>
          <linearGradient
            id="paint3_linear_odmdyhuh"
            x1="248.49"
            x2="454.19"
            y1="90.894"
            y2="460.46"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#E8E9EA"></stop>
            <stop offset="0.546" stopColor="#B4B7BB"></stop>
            <stop offset="0.979" stopColor="#B4B7BB" stopOpacity="0"></stop>
          </linearGradient>
          <linearGradient
            id="paint4_linear_nsdkfgkjvhv"
            x1="120.27"
            x2="7.964"
            y1="188.68"
            y2="217.87"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#E8E9EA"></stop>
            <stop offset="1" stopColor="#B4B7BB"></stop>
          </linearGradient>
          <filter id="dfshvmhufkgh-d" colorInterpolationFilters="sRGB">
            <feBlend in2="BackgroundImage" mode="overlay"></feBlend>
          </filter>
          <filter id="dfshvmhufkgh-c" colorInterpolationFilters="sRGB">
            <feBlend in2="BackgroundImage" mode="overlay"></feBlend>
          </filter>
          <filter id="dfshvmhufkgh-b" colorInterpolationFilters="sRGB">
            <feBlend in2="BackgroundImage" mode="overlay"></feBlend>
          </filter>
        </defs>
      </svg>
      <div className={styles.contentHolder}>{children}</div>
      {/* <svg className={styles.shadowCopy}>
        <use xlinkHref="#phoneFrame_blshnjdbkfhjvkyuvglsdrfkgjh"></use>
      </svg> */}
      <svg
        enableBackground="new"
        fill="none"
        version="1.1"
        viewBox="0 0 338 679"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.shadowCopy}
      >
        <rect
          x="8.6777"
          width="323.81"
          height="678.46"
          ry="49.204"
          fill="#3d3d3d"
        />
      </svg>
    </div>
  )
}

MobileFrame.propTypes = {
  className: PropTypes.string,
}
