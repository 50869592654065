import React from "react"
import PropTypes from "prop-types"

export default function IphonesScreens({
  color,
  backgroundColor,
  height,
  width,
  className,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height || "96"}
      fill="none"
      viewBox="0 0 99.979 96"
      className={className}
    >
      <rect
        width="44.979"
        height="80"
        x="1"
        y="15"
        stroke={color || "#000"}
        strokeWidth="2"
        rx="12"
      ></rect>
      <path
        stroke={color || "#000"}
        strokeWidth="2"
        d="M12.104 16.042c0-.575.466-1.042 1.042-1.042h19.833c.575 0 1.041.467 1.041 1.042a3.083 3.083 0 01-3.083 3.083h-15.75a3.083 3.083 0 01-3.083-3.083z"
      ></path>
      <rect
        width="44.979"
        height="80"
        x="1"
        y="1"
        stroke={color || "#000"}
        strokeWidth="2"
        rx="12"
        transform="translate(53 14)"
      ></rect>
      <g stroke={color || "#000"} strokeWidth="2" transform="translate(20.49)">
        <rect
          width="53"
          height="94"
          x="1"
          y="1"
          fill={backgroundColor || "#fff"}
          rx="12"
        ></rect>
        <path d="M14 2a1 1 0 011-1h24a1 1 0 011 1 3 3 0 01-3 3H17a3 3 0 01-3-3z"></path>
      </g>
    </svg>
  )
}

IphonesScreens.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
