import React from "react"
import PropTypes from "prop-types"

export default function GraphicaWBLogoIcon({
  color,
  backgroundColor,
  height,
  width,
  className,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "96"}
      height={height || "96"}
      fill="none"
      viewBox="0 0 96 96"
      className={className}
    >
      <rect
        width={height * 0.9792 || "94"}
        height={width * 0.9792 || "94"}
        x="1"
        y="1"
        stroke={color || "#000"}
        strokeWidth="2"
        rx="21"
      ></rect>
      <mask id="path-2-inside-1-ohuvbohjvgiv" fill={backgroundColor||"#fff"}>
        <path
          fillRule="evenodd"
          d="M41.98 10.65L19.519 23.66A13.092 13.092 0 0013 34.989v26.023a13.09 13.09 0 006.518 11.327L41.981 85.35a13.002 13.002 0 0013.037 0l22.463-13.01A13.09 13.09 0 0084 61.01V34.988a13.09 13.09 0 00-6.52-11.327L55.019 10.65a13.003 13.003 0 00-13.037 0z"
          clipRule="evenodd"
        ></path>
      </mask>
      <path
        fill={color||"#000"}
        d="M19.518 23.66l-1.002-1.73 1.002 1.73zm0 48.678l-1.003 1.73 1.003-1.73zM41.981 85.35l-1.003 1.731 1.003-1.73zm13.037 0l1.002 1.731-1.002-1.73zm22.463-13.01l1.002 1.73-1.002-1.73zm0-48.678l1.003-1.73v-.001l-1.003 1.73zM55.018 10.65L56.02 8.92l-1.002 1.73zM40.978 8.92L18.516 21.93l2.005 3.461 22.462-13.01-2.005-3.461zM18.515 21.93A15.092 15.092 0 0011 34.988h4c0-3.96 2.107-7.619 5.521-9.597l-2.006-3.46zM11 34.988v26.023h4V34.988h-4zm0 26.023a15.091 15.091 0 007.515 13.058l2.006-3.461A11.09 11.09 0 0115 61.01h-4zm7.516 13.058L40.978 87.08l2.005-3.461-22.462-13.011-2.005 3.46zM40.979 87.08a15.003 15.003 0 0015.041 0l-2.004-3.461a11.003 11.003 0 01-11.033 0l-2.004 3.461zm15.041 0L78.483 74.07l-2.005-3.461-22.462 13.01 2.005 3.462zM78.484 74.07A15.09 15.09 0 0086 61.01h-4a11.09 11.09 0 01-5.522 9.597l2.006 3.46zM86 61.01V34.988h-4v26.023h4zm0-26.023a15.091 15.091 0 00-7.516-13.058l-2.006 3.461A11.09 11.09 0 0182 34.988h4zM78.483 21.93L56.02 8.92l-2.004 3.461 22.462 13.01 2.005-3.461zM56.02 8.92a15.003 15.003 0 00-15.041 0l2.004 3.461a11.003 11.003 0 0111.033 0L56.02 8.92z"
        mask="url(#path-2-inside-1-ohuvbohjvgiv)"
      ></path>
      <mask id="path-4-inside-2-sdafasdfhfg" fill={backgroundColor||"#fff"}>
        <path
          fillRule="evenodd"
          d="M67.781 66.918c-10.648 10.448-27.915 10.448-38.562 0a26.397 26.397 0 010-37.835c10.647-10.448 27.914-10.448 38.563 0a26.397 26.397 0 010 37.835z"
          clipRule="evenodd"
        ></path>
      </mask>
      <path
        fill={color||"#000"}
        d="M29.219 66.918l1.4-1.427-1.4 1.427zm0-37.835l1.4 1.428-1.4-1.427zm38.563 0l-1.401 1.428 1.4-1.427zM66.38 65.492c-9.871 9.684-25.892 9.684-35.762 0l-2.801 2.855c11.425 11.21 29.937 11.21 41.364 0l-2.801-2.855zm-35.762 0a24.398 24.398 0 010-34.98l-2.801-2.855a28.397 28.397 0 000 40.69l2.801-2.855zm0-34.98c9.87-9.685 25.89-9.686 35.762 0l2.801-2.855c-11.426-11.212-29.939-11.212-41.364 0l2.801 2.855zm35.762 0a24.398 24.398 0 010 34.98l2.801 2.855a28.397 28.397 0 000-40.69l-2.801 2.855z"
        mask="url(#path-4-inside-2-sdafasdfhfg)"
      ></path>
      <mask id="path-6-inside-3-dghkfghkg" fill={backgroundColor||"#fff"}>
        <path
          fillRule="evenodd"
          d="M60.505 60.37c-6.63 6.831-17.38 6.831-24.01 0-6.63-6.832-6.63-17.908 0-24.74 6.63-6.83 17.38-6.83 24.01 0 6.631 6.832 6.631 17.908 0 24.74z"
          clipRule="evenodd"
        ></path>
      </mask>
      <path
        fill={color||"#000"}
        d="M36.495 60.37l1.436-1.394-1.436 1.393zm0-24.74l1.435 1.394-1.435-1.393zm24.01 0l-1.435 1.394 1.435-1.393zM59.07 58.977C53.226 65 43.775 65 37.93 58.976l-2.87 2.786c7.416 7.642 19.465 7.642 26.88 0l-2.87-2.786zm-21.14 0c-5.877-6.055-5.877-15.897 0-21.952l-2.87-2.786c-7.384 7.607-7.384 19.917 0 27.524l2.87-2.786zm0-21.952c5.845-6.022 15.296-6.022 21.14 0l2.87-2.786c-7.415-7.64-19.464-7.64-26.88 0l2.87 2.786zm21.14 0c5.878 6.055 5.878 15.897 0 21.952l2.87 2.786c7.385-7.607 7.385-19.917 0-27.524l-2.87 2.786z"
        mask="url(#path-6-inside-3-dghkfghkg)"
      ></path>
    </svg>
  )
}

GraphicaWBLogoIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
