import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "./ios.module.css"
import AppleLogoIcon from "../components/svg/appleLogoIcon"
import IOS14Icon from "../components/svg/iOS14Icon"
import IphoneBackIcon from "../components/svg/iphoneBackIcon"
import IphonesScreens from "../components/svg/iphonesScreensIcon"
import GraphicaWBLogoIcon from "../components/svg/graphicaWBLogoIcon"
import SwiftLogoIcon from "../components/svg/swiftLogoIcon"
import IOSLibraryLogoIcon from "../components/svg/iOSLibraryLogoIcon"
import BubblesContainer from "../components/svg/bubblesContainer"
import BubblesContainerMedium from "../components/svg/bubblesContainerMedium"
import BubblesContainerSmall from "../components/svg/bubblesContainerSmall"
import MobileFrame from "../components/svg/mobileFrame"
import { useWindowSize } from "@react-hook/window-size"

import SmallQRCode from "../components/svg/smallQRCode"

const IosPage = ({ data }) => {
  const [windowWidth, windowHeight] = useWindowSize()
  return (
    <Layout>
      <SEO title="iOS" />
      <div className={styles.pageWrapper}>
        <div className={`${styles.section} ${styles.first}`}>
          <div className={styles.leftSide}>
            <h2>
              graphica.ai
              <br />
              mobile for <span>iOS</span>
            </h2>
            <p className={styles.large}>
              Daily design inspiration on your iPhone. Browse images in ai-based
              feed, collect references and organize personal libraries
            </p>
            <div className={styles.buttonPlusQRContainer}>
              <a
                href="https://apps.apple.com/us/app/graphica-ai/id1541725787"
                target="_blank"
                rel="noreferrer"
              >
                <button className={`${styles.button} ${styles.black}`}>
                  <AppleLogoIcon
                    className={`${styles.platformLogo} ${styles.apple}`}
                  />{" "}
                  download iOS app
                </button>
              </a>
              <SmallQRCode className={styles.smallQRCode} />
            </div>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.mockupMobilesImage}>
              <MobileFrame className={styles.mockup}>
                <img
                  src="https://graphica.s3.eu-west-1.amazonaws.com/ios/ios-1.png"
                  alt="iphone screenshot"
                />
              </MobileFrame>
              <MobileFrame className={styles.mockup}>
                <img
                  src="https://graphica.s3.eu-west-1.amazonaws.com/ios/ios-2.png"
                  alt="iphone screenshot"
                />
              </MobileFrame>
              <MobileFrame className={styles.mockup}>
                <img
                  src="https://graphica.s3.eu-west-1.amazonaws.com/ios/ios-3.png"
                  alt="iphone screenshot"
                />
              </MobileFrame>
            </div>
          </div>
        </div>
        <div className={`${styles.section} ${styles.second}`}>
          <h3>
            the <span>next generation</span> application
            <br />
            is available on App Store
          </h3>
          <div className={styles.cardContainer}>
            <div className={styles.card}>
              <div className={styles.shadowContainer}></div>
              <div className={styles.contentContainer}>
                <IOS14Icon className={styles.image} />
                <h4>Optimized for iOS 14</h4>
                <p>
                  Application is fully compatible with iOS 14 and uses features
                  of the latest mobile operating system
                </p>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.shadowContainer}></div>
              <div className={styles.contentContainer}>
                <IphoneBackIcon className={styles.image} />
                <h4>iPhone 12 Pro friendly</h4>
                <p>
                  Graphica supports all the latest models and delivers amazing
                  iPhone 12 Pro experience
                </p>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.shadowContainer}></div>
              <div className={styles.contentContainer}>
                <IphonesScreens className={styles.image} />
                <h4>All-screen support</h4>
                <p>
                  Our application supports all screen sizes, including the
                  latest 12 Pro Max and 12 Mini
                </p>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.shadowContainer}></div>
              <div className={styles.contentContainer}>
                <GraphicaWBLogoIcon className={styles.image} />
                <h4>Powered by AI</h4>
                <p>
                  Search by keywords and colors using machine learning for image
                  recognition
                </p>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.shadowContainer}></div>
              <div className={styles.contentContainer}>
                <SwiftLogoIcon className={styles.image} />
                <h4>Based on SwiftUI</h4>
                <p className={styles.extended}>
                  Graphica uses the latest framework features to build a modern
                  user interface and native experience
                </p>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.shadowContainer}></div>
              <div className={styles.contentContainer}>
                <IOSLibraryLogoIcon className={styles.image} />
                <h4>Photos Integration</h4>
                <p>
                  Integration with Photos lets you upload images from the iPhone
                  gallery in HEIF format.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.section} ${styles.third}`}>
          <h3>
            <span>gain</span> the best inspiration
            <br />
            while exploring what you’re into
          </h3>
          <div className={styles.bubblesContainer}>
            {windowWidth >= 1680 && (
              <BubblesContainer className={styles.bubblesContainerSVG} />
            )}
            {windowWidth >= 680 && windowWidth < 1680 && (
              <BubblesContainerMedium className={styles.bubblesContainerSVG} />
            )}
            {windowWidth < 680 && (
              <BubblesContainerSmall className={styles.bubblesContainerSVG} />
            )}
          </div>
        </div>
        <div className={`${styles.section} ${styles.fourth}`}>
          <h3>
            <span>collaborate</span> and share
            <br />
            your inspiration with others
          </h3>
          <div className={styles.phoneColumnsContainer}>
            <div className={styles.column}>
              <MobileFrame>
                <img
                  src="https://graphica.s3.eu-west-1.amazonaws.com/ios/Image+1.jpg"
                  alt="iphone screenshot"
                />
              </MobileFrame>
              <h5>
                Browse, search 
                <br />
                and save the best images
              </h5>
            </div>
            <div className={styles.column}>
              <MobileFrame>
                <img
                  src="https://graphica.s3.eu-west-1.amazonaws.com/ios/2-library+1.jpg"
                  alt="iphone screenshot"
                />
              </MobileFrame>
              <h5>
                Organize and curate
                <br />
                your private libraries
              </h5>
            </div>
            <div className={styles.column}>
              <MobileFrame>
                <img
                  src="https://graphica.s3.eu-west-1.amazonaws.com/ios/3-invite+1.jpg"
                  alt="iphone screenshot"
                />
              </MobileFrame>
              <h5>
                Invite collaborators
                <br />
                and create shared libraries
              </h5>
            </div>
            <div className={styles.column}>
              <MobileFrame>
                <img
                  src="https://graphica.s3.eu-west-1.amazonaws.com/ios/4-profile+1.jpg"
                  alt="iphone screenshot"
                />
              </MobileFrame>
              <h5>
                Maximize your public
                <br />
                exposure and influence
              </h5>
            </div>
          </div>
        </div>
        <div className={`${styles.section} ${styles.fifth}`}>
          <h3>
            choose the best plan
            <br />
            that <span>suits you</span>
          </h3>
          <div className={styles.cardContainer}>
            <div className={styles.card}>
              <div className={styles.shadowContainer} />
              <div className={styles.contentContainer}>
                <div className={styles.leftSide}>
                  <img
                    src="https://graphica.s3.eu-west-1.amazonaws.com/ios/BasicPlanImage.jpg"
                    alt="FREE plan"
                  />
                </div>
                <div className={styles.rightSide}>
                  <p className={styles.planName}>Personal</p>
                  <p className={styles.coast}>
                    <span>FREE</span>
                  </p>
                  <ul>
                    <li>Unlimited saved posts</li>
                    <li>Public and private libraries</li>
                    <li>Public projects and profile</li>
                    <li>Up to 100 image uploads</li>
                    <li>Up to 3 collaborators</li>
                    <li>First level libraries</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.shadowContainer} />
              <div className={styles.contentContainer}>
                <div className={styles.leftSide}>
                  <img
                    src="https://graphica.s3.eu-west-1.amazonaws.com/ios/ProPlanImage.jpg"
                    alt="PRO plan"
                  />
                </div>
                <div className={styles.rightSide}>
                  <p className={styles.planName}>PRO</p>
                  <p className={styles.coast}>
                    <span>$7 </span>monthly, or $70 annually
                  </p>
                  <ul>
                    <li>Featuring in graphica feed</li>
                    <li>Unlimited images uploads</li>
                    <li>Unlimited amount of libraries</li>
                    <li>Unlimited levels of libraries</li>
                    <li>Unlimited collaborators</li>
                  </ul>
                  <p className={styles.description}>
                    All payment details will be available before your in-app purchases
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IosPage

